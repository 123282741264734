import React, { FC, useContext, useEffect } from "react";
import { Heading, Stack, Box } from "@chakra-ui/react";
import { LayoutContext } from "../../components/layout";
import { graphql, PageProps } from "gatsby";
import { BlockFragment, PageTemplateQuery } from "../../../graphql-types";
import { FluidObject } from "gatsby-image";
import BackgroundImage from "gatsby-background-image";
import { Blocks } from "../../components/blocks";
import { Container } from "../../components/container";
import { Footer } from "../../components/footer";
import { BrochureFooter } from "../../components/brochure/footer";
import { motion } from "framer-motion";

export const query = graphql`
  query PageTemplate($id: String!) {
    wpPage(id: { eq: $id }) {
      id
      title
      blocks {
        ...Block
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;

const PageTemplate: FC<PageProps<PageTemplateQuery>> = props => {
  const { data } = props;
  const page = data.wpPage;

  const { mapMachine } = useContext(LayoutContext);
  const [, mapSend] = mapMachine as NonNullable<typeof mapMachine>;

  useEffect(() => {
    mapSend("HIDE_MAP");
  }, [mapSend]);

  return (
    <motion.div
      key={props.path}
      initial={{ opacity: 0, x: "100%" }}
      animate={{ opacity: 1, x: "0%" }}
      exit={{ opacity: 0, x: "-100%" }}
      transition={{ duration: 0.5 }}
    >
      <main>
        <BackgroundImage
          fluid={[
            `linear-gradient(rgba(0, 0, 0, 0.666), rgba(0, 0, 0, 0.404588))`,
            ...(page?.featuredImage
              ? [
                  page.featuredImage.node?.localFile?.childImageSharp
                    ?.fluid as FluidObject,
                ]
              : []),
          ]}
        >
          <Box pt={48} pb={16}>
            <Container maxW="xl">
              <Stack align="flex-start">
                <Heading
                  color="white"
                  fontFamily="korolevCompressed"
                  textTransform="uppercase"
                  fontSize={{ base: "5xl", md: "7xl" }}
                >
                  {page?.title}
                </Heading>
              </Stack>
            </Container>
          </Box>
        </BackgroundImage>

        <Blocks blocks={page?.blocks as BlockFragment[]} />
      </main>

      <footer>
        <BrochureFooter />
        <Footer />
      </footer>
    </motion.div>
  );
};

export default PageTemplate;
